import React, { Component } from 'react';
import { Radio, Row } from 'antd';
import { stadiumsRef, teamsRef } from './api';
import Stadium from './Stadium';
import TeamForm from './TeamForm';
import Payment from './Payment';
import logos from './img/logos.png';
import pattern from './img/pattern.png';
import 'react-credit-cards/es/styles-compiled.css';
import './App.css';


class App extends Component {
  state = {
    isLoading: false,
    stadiumList: [],
    selected: null,
    step: 0,
    team: null,
    teamData: null
  };

  async componentDidMount() {
    if (!window.location.pathname.substring(1)) {
      const qs = await stadiumsRef.get();
      const stadiumList = qs.docs.map(stadium => ({
        id: stadium.id,
        ...stadium.data()
      }));
      this.setState({ stadiumList});
    } else {
      const res = await teamsRef.doc(window.location.pathname.substring(1)).get();
      if (res.exists) {
        console.log('Res', res.id);
        const data = res.data();
        this.setState({
          step: 2,
          team: res.id,
          teamData: data
        })
      } else {
        window.location = 'http://tl-football.firebaseapp.com'
      }
    }
  }

  onSelectStadium = selected => {
    this.setState({ selected, step: 1 })
  }

  handleSaveTeam = team => {
    this.setState({ step: 2, team });
  }

  handleNavigate = step => {
    this.setState({ step: step.target.value })
  }

  render() {
    const { selected, stadiumList, step, team, teamData } = this.state;
    return (
        <div>
          <div className="App-header">
            <img alt="logo" src={logos} style={{ height: '70px'}} />
            <div className="hero">
              <h1 style={{ fontSize: '40px', marginBottom: 0, fontWeight: 'bold', color: 'white' }}>
                Register Now!
              </h1>
              <h2 style={{ fontSize: '25px', marginBottom: 0, fontWeight: 'bold', color: 'white' }}>
                Zain Ramadan Football Tournament
              </h2>
            </div>
            <img alt="logo" src={pattern} className="pattern" />
          </div>
          <Radio.Group
            value={step}
            buttonStyle="solid"
            onChange={this.handleNavigate}
            style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}
          >
            <Radio.Button value={0} disabled={!!team}>Stadium</Radio.Button>
            <Radio.Button value={1} disabled={!selected || !!team}>Registration</Radio.Button>
            <Radio.Button value={2} disabled={!team}>Payment</Radio.Button>
          </Radio.Group>
          <div style={{ padding: '16px 24px'}}>
            {step === 0 && (
              <Row gutter={16}>
                {stadiumList.map(stadium => (
                  <Stadium key={stadium.id} stadium={stadium} onSelect={this.onSelectStadium} selected={selected} />
                ))}
              </Row>
            )}
            {step === 1 && (
              <Row gutter={16}>
                <TeamForm onSave={this.handleSaveTeam} team={team} stadium={selected}/>
              </Row>
            )}
            {step === 2 && (
              <Row gutter={16}>
                <Payment teamId={team} data={teamData}/>
              </Row>
            )}
          </div>
        </div>
    );
  }
}

export default App;
