import React, { Component } from 'react';
import { pay, teamsRef, register, stadiumsRef } from './api';
import { Button, Modal } from 'antd';

class Payment extends Component {
  state = {
    teamData: null,
    error: null,
    visible: false,
    isStadium: false
  }

  async componentDidMount() {
    const charge = window.location.search.substring(1).replace('tap_id=', '');
    teamsRef.doc(this.props.teamId).get().then(async (doc) => {
      let teamData = doc.data();
      if (!!charge && teamData) {
        const res = await register({
          charge,
          teamId: this.props.teamId,
          stadiumId: teamData.stadiumId
        });
        if (res.ok) {
          teamsRef.doc(this.props.teamId).get().then(async (doc) => {
            teamData = doc.data();
          });
        } else {
          this.setState({
            error: res.error
          });
        }
      }
      this.setState({ teamData });
      stadiumsRef.doc(teamData.stadiumId).get().then(async (doc) => {
        this.setState({ isStadium: doc.data().teams < 4})
      })
    });
  }

  handleClick = async () => {
    const res = await pay({
      id: this.props.teamId,
      ...this.state.teamData
    });
    const data = res.data;
    if (!data.errors) {
      window.location = data.transaction.url;
    }
  }

  handleClose = () => {
    this.setState({ visible: false })
  }

  openModal = () => {
    this.setState({ visible: true})
  }

  renderModal = () => (
    <Modal
      title="Terms and Conditions"
      visible={this.state.visible}
      footer={null}
      closable
      onCancel={this.handleClose}
    >
      <h4>Payment</h4>
      <ol>
        <li>Registration is through the following link:  https://tl-football.firebaseapp.com/</li>
        <li>Each team can register one team per pitch only</li>
        <li>Teams have to pay an insurance fee of 50bd only, the insurance fee will be refunded after the tournament</li>
      </ol>
      <h4>Tournament rules</h4>
      <ol>
        <li>Teams will be split into groups of 4 split across 8 stadiums, first and second place of each group will advance to the next stage
        </li>
        <li>The team finishing first will play on the same pitch in the round of 16</li>
        <li>The finals (quarter final to final) will be played on Gravity pitch at zallag on the 20th of may</li>
        <li>Each team is allowed to register 12 players, 7 main players and 4 substitute players</li>
        <li>Match duration is 20 minutes in the group stages and 30 minutes in the knockout stages</li>
        <li>In the case of a tie in the group stages, the difference of goals will determine the team that advances, if there is a tie in the difference of goals, then scored goals will be the determining factor</li>
        <li>If the team arrives 5 or more minutes late, they will not be able to play their schedule game and will lose by 3 goals</li>
        <li>Each team can bench 5 players and one administrative representative</li>
        <li>If an unregistered player is part of any team, the team will be disqualified and will lose by 3 goals</li>
        <li>Any decision issued by the organizational committee is final, and must be adhered by all parties</li>
        <li>International football rules apply</li>
        <li>Any inappropriate language used with other players or the referee will result in the disqualification of the player. The team will continue the tournament without a substitution</li>
        <li>The organizational committee has the right to suspend or disqualify any team from the tournament in the case of misconduct</li>
        <li>The organization committee has the right to make amendments on the terms of the tournament before the match schedule is issued</li>
        <li>Withdrawal from any game will result in the disqualification from the tournament</li>
      </ol>
    </Modal>
  );

  render() {
    const { teamData, error, isStadium } = this.state;
    return teamData && (
      <div className="team-form">
        <p>{teamData.paid ? 'You are in!' : 'Please pay now to finish registration'}</p>
        {error && (
          <p>Error: {error}</p>
        )}
        <p>Your team link is:</p>
        <p>https://tl-football.firebaseapp.com/{this.props.teamId}</p>
        <p>Please save it for payments and check your status.</p>
        {!isStadium && (
          <p>Sorry quota is full!</p>
        )}
        <a href="#" onClick={this.openModal}>Terms and Conditions</a>
        <p>For any questions contact us at <a href="mailto:hello@malaebapp.com">hello@malaebapp.com</a></p>
        {(!teamData.paid && isStadium) && (
          <Button onClick={this.handleClick}>Pay Now</Button>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

export default Payment;
