import * as firebase from "firebase/app";
import 'firebase/firebase-firestore';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyB03LU-A69kK3w-BLEWPAnK13E_4LlUzkk",
  authDomain: "tl-football.firebaseapp.com",
  databaseURL: "https://tl-football.firebaseio.com",
  projectId: "tl-football",
  storageBucket: "tl-football.appspot.com",
  messagingSenderId: "452722418593"
};

firebase.initializeApp(config);

export const db = firebase.firestore();
export const functions = firebase.functions();
export const pay = functions.httpsCallable('pay');
export const register = functions.httpsCallable('register');
export const stadiumsRef = db.collection('stadiums');
export const teamsRef = db.collection('teams');

