import React, { Component } from 'react';
import { Card, Col, Rate, Icon } from 'antd';

const { Meta } = Card;

class Stadium extends Component {
  render() {
    const { stadium, onSelect, selected } = this.props;
    return stadium && (
      <Col key={stadium.id} md={8} lg={6} style={{ marginBottom: '15px' }}>
        <Card
          hoverable
          cover={<img alt={stadium.name} src={require(`./img/${stadium.id}.png`)} />}
          onClick={() => {
            if (stadium.teams !== 4) {
              onSelect(stadium.id)
            }
          }}
          style={selected === stadium.id ? { border: '1px solid  #00A28F'} : {}}
        >
          <Meta
            title={stadium.name}
            description={stadium.address}
          />
          <div style={{ float: 'right'}}>
            <p className="team">{stadium.teams} OUT 4 TEAMS</p>
            <Rate character={<Icon type="user" />} value={parseInt(stadium.teams, 10)} disabled count={4} />
          </div>
        </Card>
      </Col>
    )
  }
}

export default Stadium;
