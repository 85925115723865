import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { teamsRef } from './api'

class TeamForm extends Component {
  state = {
    isLoading: false
  }

  onSubmit = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true});
        try {
          const res = await teamsRef.add({
            stadiumId: this.props.stadium,
            ...values,
            paid: false
          });
          this.props.onSave(res.id);
        } catch (e) {
          console.log('Error saving ')
        }
      }
    });
  }

  render() {
    const { team } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical" className="team-form">
        <Form.Item label="Team name">
          {getFieldDecorator('name', {
            rules: [{ required: true }],
            initialValue: team ? team.name : ''

          })(
            <Input placeholder="Team Name" />
          )}
        </Form.Item>
        <Form.Item label="Team Manager">
          {getFieldDecorator('manager', {
            rules: [{ required: true }],
            initialValue: team ? team.manager : ''
          })(
            <Input placeholder="Team Manager Name" />
          )}
        </Form.Item>
        <Form.Item label="Mobile">
          {getFieldDecorator('mobile', {
            rules: [{ required: true, len: 8 }],
            initialValue: team ? team.mobile : ''
          })(
            <Input addonBefore="+973" placeholder="Team Manager Mobile Number" />
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [{ required: true, type: 'email' }],
            initialValue: team ? team.email : ''
          })(
            <Input placeholder="Team Manager email" />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            onClick={this.onSubmit}
            style={{
              backgroundColor: '#00A28F',
              color: 'white',
              fontWeight: 'bold',
              padding: '0 60px',
              margin: '20px 0'
            }}
            loading={this.state.isLoading}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'team-form' })(TeamForm);